<script lang="ts" setup>
definePageMeta({
  layout: 'unauthenticated',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const { $i18n } = useNuxtApp()
useHead({ title: `${$i18n.t('auth.login.title')} - ${$i18n.t('app.title')}` })

const formData = ref<{ email: string, password: string }>({
  email: '',
  password: '',
})

const submitError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const { $trpc, useMutation } = useMutationHelpers()
const submit = useMutation({
  mutationFn: $trpc.verificationTokens.sendVerificationToken.mutate,
  onSuccess: async () => {
    return navigateTo({ path: '/auth/verification', query: { email: formData.value.email } })
  },
})
const submitHandler = async () => {
  isLoading.value = true
  formData.value.email = formData.value.email.toLowerCase()
  submit.mutate({ email: formData.value.email })
}

const institutionSelfRegister = useRuntimeConfig().public.institutionSelfRegister
</script>

<template>
  <TheDataCard
    :title="$t('auth.login.title')"
    header-class="bg-gray-200 !justify-center"
    class="max-w-lg mx-auto"
  >
    <n-alert v-if="submitError" :title="$t('auth.login.error.title')" type="error" class="mb-4">
      {{ $t('auth.login.error.text') }}
    </n-alert>

    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      incomplete-message=" "
      @submit="submitHandler"
    >
      <div class="flex flex-col gap-2">
        <FormKit
          name="email"
          type="text"
          :label="$t('auth.emailOrPhone.label')"
          :validation-label="$t('auth.emailOrPhone.placeholder')"
          :placeholder="$t('auth.emailOrPhone.placeholder')"
          validation="required"
          :disabled="isLoading"
          :classes="{
            outer: '$reset mb-2 w-full',
            input: 'w-full',
          }"
        />
      </div>
      <n-divider />
      <n-button type="primary" size="large" attr-type="submit" :disabled="isLoading" :loading="isLoading" class="w-full">
        {{ $t('button.next') }}
      </n-button>
    </FormKit>
    <NuxtLink :to="{ path: '/auth/register', query: { type: 'citizen' } }">
      <n-button secondary size="large" class="w-full mt-2">
        {{ $t('button.register.citizen') }}
      </n-button>
    </NuxtLink>
    <NuxtLink v-if="institutionSelfRegister" :to="{ path: '/auth/register', query: { type: 'institution' } }">
      <n-button secondary size="large" class="w-full mt-2">
        {{ $t('button.register.institution') }}
      </n-button>
    </NuxtLink>
  </TheDataCard>
</template>
